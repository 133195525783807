import { post, get, patch, formData, clientId } from '../../washtraxClient'

const customer_report_list = data => {
  return post(`ticket/filter `, {
    customers: data.customers,
    asset_id: data.assetId,
    // "limit":10,
    offset: 0,
    attributes: {
      basic: [
        'asset_txns_id',
        'quantity',
        'last_known',
        'driver_name',
        'job_site',
        'remark',
        'check_in',
        'ten_days_alert'
      ],
      asset_txns_status: ['param_value', 'param_description'],
      client_details: ['company_name', 'first_name', 'last_name'],
      customer_details: ['name', 'clients_cust_ref_id', 'clients_cust_id'],
      yard_details: ['name'],
      po_details: ['po_no', 'po_value'],
      asset_txns_details: ['field_name', 'field_value'],
      asset_details: {
        basic: ['equipment_no', 'asset_id', 'equipment_type', 'owner'],
        equipment_type_details: [
          'param_value',
          'param_description',
          'parent_id'
        ]
      },
      ticket_details: {
        basic: [
          'ticket_id',
          'ticket_ref_id',
          'estimate_price',
          'invoice_price',
          'parent_id'
        ],
        ticket_txns_details: ['created_at', 'parameter_name', 'days_alert'],
        ticket_status: ['param_value', 'param_description']
      }
    }
  })
}
export default {
  customer_report_list
}
