import { post, get, patch } from "../washtraxClient"

const purchaseList = data => {
  return get(`po/list`)
}

const customerList = data => {
  return get(`customer/list`)
}

const getPOServiceList = data => {
  return get(`service/list-for-selectbox`)
}

const checkponumber = (poNumber, custId) => {
  return get(`po/uniquecheck?poNumber=${poNumber}&custId=${custId}`)
}

const addPurchaseOrder = data => {
  return post("po/add", data)
}
const updatePurchaseOrder = data => {
  return patch("po/update", data)
}

const customerActives = data => {
  return get(`customer/list/for-selection `)
}
const getRailService = data => {
  return get(`service/list-for-selectbox/pre`)
}

export default {
  customerActives,
  updatePurchaseOrder,
  purchaseList,
  customerList,
  getPOServiceList,
  checkponumber,
  addPurchaseOrder,
  getRailService,
}
