import {get} from '../washtraxClient';

const alertStatus = () => {
  return get (`/dashboard/alertStatus`);
};

const dashboardCount = () => {
  return get (`/dashboard/counts`);
};
const revenuesGenerated=(count)=>{
  return get (`/getRevenuesGeneratedByCustomers/${count}`);
}
const washStatus=()=>{
  return get (`/dashboard/getWashStatusStats`);
}

const pickupList=()=>{
  return get (`/dashboard/getAwaitingPickUpList`);
}

const approvalList=()=>{
  return get (`/dashboard/getAwaitingApprovalList`);
}
const monthlyEstimate=(count)=>{
  return get (`/dashboard/getTotalOfEstimateByMonth`);
  // return get (`/dashboard/getTotalOfEstimateByMonth/${count}`);

}

const monthlyTicket=(count)=>{
  return get (`/dashboard/getNumberOfTicketsByMonth`);
  // return get (`/dashboard/getNumberOfTicketsByMonth/${count}`);

}

const monthlyInvoice=(count)=>{
  return get (`/getInvoiceMonthlyReport`);
  // return get (`/getInvoiceMonthlyReport/${count}`);

}
export default {
    alertStatus,
    monthlyInvoice,
    revenuesGenerated,
    monthlyEstimate,
    monthlyTicket,
    dashboardCount,
    washStatus,
    pickupList,
    approvalList
};
