import {post, patch} from '../washtraxClient';

const mailWithOutAttachment = data => {
  return post (`/emailNoAttachment`, data);
};
const mailEstimate = data => {
  return post ('/emailDateEstimate', data);
};

const mailWashOutCertificate = data => {
  return post ('/emailWashOutCertificate', data);
};

const mailInvoice = data => {
  return post ('/emailInvoice', data);
};

const updateInvoice = data => {
  return patch (`updateInvoice`, data)
}
 
export default {
  updateInvoice,
  mailWithOutAttachment,
  mailEstimate,
  mailWashOutCertificate,
  mailInvoice,
};
