import { get, post, patch, put } from "../washtraxClient"

const getZipData = data => {
  return get(`getzipdata/${data}`)
}
const updateSettings = (data, option) => {
  return post(`client/upload/logo`, data, option)
}
const uploadImage = (data, option) => {
  return post("image/upload", data, option)
}
const shareImage = data => {
  return post("mail/equipment/Imagedocs", data)
}
const updateServices = (id, data) => {
  return patch(`/ticket/update/certificate/services/${id}`, data)
}
const updateCertificateNumber = (id, data) => {
  return patch(`/ticket/update/certificate/${id}`, data)
}
const preWashToggle = data => {
  return post("client/update-settings", data)
}
const yardReportEmail = data => {
  return post("send/email-attachment", data)
}
const certificateServices = () => {
  return get("get/certificate/services")
}
const quickBook = data => {
  return post("/quickbook/integrate/keys", data)
}
const quickBookStatus = status => {
  return put(`/quickbook/integrate/change/status/${status}`)
}
const updateCertificateComments = data => {
  return patch(`/ticket/update-certificate`, data)
}
export default {
  getZipData,
  quickBookStatus,
  quickBook,
  updateSettings,
  uploadImage,
  shareImage,
  updateServices,
  updateCertificateNumber,
  preWashToggle,
  yardReportEmail,
  certificateServices,
  updateCertificateComments,
}
