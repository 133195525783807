import {post, get, patch} from '../washtraxClient';

const clientList = data => {
  return get (`/client/list`);
};
const addClients = data => {
  return post (`/client/add`, data);
};
const getClientDetails = data => {
  return get (`client/details/${data}`);
};
const EditClients = data => {
  return patch (`client/update`, data);
};

const addClientUser = (data, client_id) => {
  return post (`/user/signup/${client_id}`, data);
};

const changeClientStatus = data => {
  return patch (
    `client/account/changeStatus?clientId=${data.clientId}&status=${data.status}`
  );
};

const getPrimaryUser = clientID => {
  return get (`client/primaryUser/${clientID}`);
};

const getIndustryList=()=>{
  return get('/client/industry/list')
}
export default {
  clientList,
  addClients,
  getClientDetails,
  EditClients,
  changeClientStatus,
  getPrimaryUser,
  addClientUser,
  getIndustryList
};
