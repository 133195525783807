import {get,post} from '../../washtraxClient';
import {IndustryType} from '../../../utils/helper';
const InYardList = (page,searchText) => {
    const basic =   ["quantity","last_known", "driver_name", "job_site","remark","ten_days_alert","check_in"]
    if(IndustryType()==="Food_Grade"){
      basic.push("truck_number")
    }
    return post(`/ticket/list`,
    {
        "limit": 100,
        "search": "",
        "offset": 0,
        "attributes": {
            "basic": [
                "ticket_id",
                "ticket_ref_id",
                "certificate_no"
            ],
            "ticket_details": [
                "field_name",
                "field_value",
                "created_at"
            ],
            "ticket_status": [
                "param_value",
                "param_description"
            ],
            "asset_txns_details": {
                "basic": [
                    "quantity",
                    "last_known",
                    "ten_days_alert",
                    "check_in"
                ],
                "asset_type_details": [
                    "param_value",
                    "param_description"
                ],
                "asset_txns_details": [
                    "field_name",
                    "field_value"
                ]
            },
            "asset_details": [
                "equipment_no",
                "asset_id",
                "equipment_type",
                "owner"
            ],
            "customer_details": [
                "name",
                "clients_cust_ref_id",
                "clients_cust_id"
            ],
            "ticket_txns_details": [
                "parameter_name",
                "days_alert",
                "created_at"
            ]
        }
    }
    // {
    //     "limit":100,
    //     "search":searchText,
    //     "offset":page,
    //     "attributes":{
    //         "basic":[
    //             "ticket_id",
    //             "ticket_ref_id",
    //             "estimate_price",
    //             "invoice_price",
    //             "created_at",
    //             "remark",
    //             "certificate_no",
    //         ],
    //         "ticket_status":["param_value", "param_description"],
    //         "asset_txns_details":{
    //             "basic":basic,
    //             "asset_type_details":["param_value", "param_description"],
    //             "yard_details":["name", "address", "coordinates"],
    //             "asset_txns_details":["field_name", "field_value"]
    //         },
    //         "asset_details":["equipment_no", "asset_id", "equipment_type", "owner"],
    //         "customer_details":["name", "clients_cust_ref_id", "clients_cust_id"],
    //         "po_details":["po_no", "po_value"],
    //         "sub_tickets":["ticket_id","ticket_ref_id","description","service_id","estimate_price","invoice_price","discount"],
    //         "ticket_txns_details":["parameter_name","days_alert","created_at"],
    //         "ticket_details":["field_name","field_value","created_at"]
    //     }
    // }
    );
};

export default {
    InYardList,
};
