import  {post,get,patch,_delete}  from '../washtraxClient';

  const poNumber = (data) => {
    return get(`poNumberListByCustomerID/${data}`);
  };
const assetSearch=(custId)=>{
  return get(`asset/search/${custId}`);
}
const equipmentCheckin=(data)=>{
  return post(`equipment/checkin`,data);
}
const getEquipmentData=(assetTxnId)=>{
  return get(`equipment/checkInData/${assetTxnId}`);
}

const editEquipmentData=(data)=>{
  return patch(`equipment/checkin/edit`,data);
}

const approveEquipmentData=(data)=>{
  return post(`asset-checkin/approve`,data);
}
const rejectEquipment=(data)=>{
  return patch(`reject/checkedInEquipment`,data);
}

const fetchTicketNo=(data)=>{
  if(data){
    return post(`validate/ticket`,data)
  }
  else{
    return post(`validate/ticket`)
  }
}

const checkedinEquipment=()=>{
  return get('checkedin-equipment-list')
}

const equipmentCheckout=(data)=>{
  return post('equipment/checkout',data)
}

const imageUpdate=(data)=>{
  return post('add/assetWashOut/docs',data)
}

const imageDelete=(docId,url)=>{
  return _delete(`delete/assetWashOut/docs/${docId}`,url)
}
const washTypeRequest=()=>{
  return get(`/service/main/foodgrade/list`)
}

const equipmentNo = (id)=> {
  return get(`/asset/list/${id}`)
}

const certificateList = (id) => {
  return get(`certificate/list/${id}`)
}

export default {
  certificateList,
  equipmentNo,
    poNumber,
    washTypeRequest,
    assetSearch,
    equipmentCheckin,
    getEquipmentData,
    editEquipmentData,
    approveEquipmentData,
    rejectEquipment,
    checkedinEquipment,
    equipmentCheckout,
    imageUpdate,
    imageDelete,
    fetchTicketNo
};