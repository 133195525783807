import {get, patch,post} from '../washtraxClient';
import {IndustryType} from '../../utils/helper';
const attentionList = (data) => {
  const storageData = JSON.parse(localStorage.getItem('data'));

  const status = storageData.TabParams.filter((val, index) => {
    if (
      val.param_name == 'Awaiting Approval' &&
      val.param_key == 'ticket_status'
    ) {
      return val;
    }
  });
const basic =   ["quantity","last_known", "driver_name", "job_site","remark","ten_days_alert"];
if(IndustryType()==="Food_Grade"){
  basic.push("truck_number")
}
  return post(`/ticket/list`,{
    "status":[status[0].param_value],
    // "limit":10,
    // "offset":0,
    "attributes":{
        "basic":[
            "ticket_id",
            "ticket_ref_id",
            "estimate_price",
            "invoice_price",
            "created_at",
            "remark",
        ],
        "ticket_status":["param_value", "param_description"],
        "asset_txns_details":{
            "basic":basic,
            "asset_type_details":["param_value", "param_description"],
            "yard_details":["name", "address", "coordinates"],
            "asset_txns_details":["field_name", "field_value"]
        },
        "asset_details":["equipment_no", "asset_id", "equipment_type", "owner"],
        "customer_details":["name", "clients_cust_ref_id", "clients_cust_id"],
        "po_details":["po_no", "po_value"],
        "sub_tickets":["ticket_id","ticket_ref_id","description","service_id","estimate_price","invoice_price","discount"],
        "ticket_txns_details":["parameter_name","days_alert","created_at"]
    }
});
};

const AcceptRejectAction = (data) => {
  return patch('ticket/update-status', data);
};
export default {
  attentionList,
  AcceptRejectAction,
};
