import  {post,get,patch}  from '../washtraxClient';

  const userList = (data) => {
    return get(`client/userList`);
  };
  const addUser = (data) => {
    return post(`client/addUser`,data);
  };
  const getUserDetails = (data) => {
    return get(`/user/${data}`);
  };
  const editUser = (data) => {
    return patch(`user/edit`,data);
  };

  const changeUserStatus = (data) => {
    return patch(`user/account/ChangeStatus?id=${data.userId}&status=${data.status}`);
  };
export default {
    userList,
    addUser,
    getUserDetails,
    editUser,
    changeUserStatus
};