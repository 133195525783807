import { post, get, patch, _delete } from '../washtraxClient';

const yardList = (data) => {
  return get(`/yard/list`);
};
const yardTicket = (ticketId) => {
  return get(`yard/ticket/${ticketId}`);
};
const yardEstUpdate = (data) => {
  return patch(`ticket/estimation-update`, data);
};
const ticketDetails = (ticket_id) => {
  return get(`/ticket/details/${ticket_id}`);
};
const yardTicketPerforma = (ticket_id) => {
  return get(`/getTicketPerformaDetails/${ticket_id}`);
};

const yardTicketInvoice = (ticket_id) => {
  return get(`/getTicketInvoiceDetails/${ticket_id}`);
};

const deletePerforma = (ticketId, coordinate) => {
  return post(`/deleteSubTicket/${ticketId}`, coordinate);
};
const undoPerforma = (ticketId, coordinate) => {
  return post(`/undoDeletedSubticket/${ticketId}`, coordinate);
};
const savePerforma = (data) => {
  return post(`/savePerforma`, data);
};

const createInvoice = (data) => {
  return post(`/createInvoice`, data);
};

const addSubTicket = (data) => {
  return post(`/performaAddSubticket`, data);
};

const editQuantity=(data)=>{
  return patch(`/yard/subticket/update`, data);
}
const quickBookInvoice=(ticket_id)=>{
  return get(`/quickbook/create/invoice/${ticket_id}`)
}
export default {
  editQuantity,
  quickBookInvoice,
  yardList,
  yardTicket,
  yardEstUpdate,
  ticketDetails,
  yardTicketPerforma,
  yardTicketInvoice,
  deletePerforma,
  undoPerforma,
  savePerforma,
  createInvoice,
  addSubTicket,
};
