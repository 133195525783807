import  {post,get,patch}  from '../washtraxClient';

  const customerList = (data) => {
    return get(`customer/list`);
  };
  const addCustomer = (data) => {
    return post(`/customer/add`,data);
  };
  const getCustomerDetails = (data) => {
    return get(`customer/details/${data}`);
  };
  const editCustomer = (data) => {
    return patch(`customer/update`,data);
  };

  const changeCustomerStatus = (data) => {
    return patch(`/customer/changeCustomerStatus?custId=${data.customerId}&status=${data.status}`);
  };
export default {
    customerList,
    addCustomer,
    getCustomerDetails,
    editCustomer,
    changeCustomerStatus
};