import {get,post} from '../../washtraxClient';
import {IndustryType} from '../../../utils/helper';
const closedOut = (id,page,searchText) => {
    const basic =   ["asset_txns_id","quantity","last_known","driver_name","job_site","remark","check_in","ten_days_alert"];
if(IndustryType()==="Food_Grade"){
  basic.push("truck_number")
}
    return post(`asset-checkin-list`,{

        "status":[id],
        "limit":100,
        "search":searchText,
        "offset":page,
        "attributes": {
            "basic": [
                "asset_txns_id"
            ],
            "customer_details": [
                "name",
                "clients_cust_ref_id",
                "clients_cust_id"
            ],
            "asset_details": {
                "basic": [
                    "equipment_no",
                    "asset_id",
                    "equipment_type"
                ],
                "equipment_type_details": [
                    "param_value",
                    "param_description",
                    "parent_id"
                ]
            },
            "ticket_details": {
                "basic": [
                    "ticket_id",
                    "ticket_ref_id",
                    "certificate_no",
                    "status"
                ]
            }
        }
        // "attributes":{
    
        //     "basic":basic,
        //     "asset_txns_status":["param_value","param_description"],
        //     "customer_details":["name","clients_cust_ref_id","clients_cust_id"],
        //     "yard_details":["name"],
        //     "po_details":["po_no","po_value"],
        //     "asset_txns_details":["field_name","field_value","created_at"],
        //     "asset_details":{
    
        //         "basic":["equipment_no","asset_id", "equipment_type"],
        //         "equipment_type_details":["param_value","param_description","parent_id"]
        //     },
        //     "ticket_details":{
    
        //         "basic":["ticket_id", "ticket_ref_id", "estimate_price", "invoice_price","status","certificate_no"],
        //         "ticket_txns_details":["created_at","parameter_name","days_alert"]
        //     }
        // }
    });
};

export default {
    closedOut,
};
