import  {post,get,patch,_delete}  from '../washtraxClient';


  const serviceList = (data) => {	  
    return get(`/service/list`);
  	};
const addService = (data,newService) => {
  if(newService){
    return post('service/add/new',data)
  }else{
    return post(`service/add`, data);
  }
  
};
const addNewService=(data)=>{
  return post('service/add/new',data)
}

const getService=(data)=>{
  return get(`/get/serivces/${data}`);
}

const updateService=(data)=>{
  return patch(`service/update`, data);
}
const deleteService=(id)=>{
  return _delete(`service/delete/${id}`);
}
const changeStatus = (data) => {
  return patch(`/service/changeStatus`, data);
};
export default {    
  serviceList,
  getService,
    addService,
    addNewService,
    changeStatus,
    updateService,
    deleteService
};