import {post, get, patch} from '../washtraxClient';

const locationList = data => {
  return get (`yard/list`);
};
const yardAdd = data => {
  return post ('yard/add', data);
};
const yardEdit = data => {
  return patch ('yard/update', data);
};
export default {
  locationList,
  yardAdd,
  yardEdit,
};
